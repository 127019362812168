import "./Settings.scss";
import React, { Component } from "react";
import SettingsIcon from "../icon-cog.svg";
import Timer from "./Timer.js";
import Toggle from "./Toggle.js";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			darkModeEnabled: false,
			cbModeEnabled: false,
			format: null,
			practiceMode: false,
		};
		this.toggleDarkMode = this.toggleDarkMode.bind(this);
		this.toggleCbMode = this.toggleCbMode.bind(this);
		this.handleChangePracticeMode = this.handleChangePracticeMode.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	// Initialise dark mode based on localStorage / OS settings
	componentDidMount() {
		// Dark mode
		const localDarkModeEnabled = window.localStorage.getItem("enchant-worldle-dark-mode-enabled");
		let darkModeEnabled = window.matchMedia("(prefers-color-scheme: dark)").matches;
		if (localDarkModeEnabled !== null) {
			darkModeEnabled = localDarkModeEnabled === "true"; // Cast as a bool
		}
		this.setState({
			darkModeEnabled: darkModeEnabled,
		});
		this.setDarkModeBodyClasses(darkModeEnabled);

		// Colour blind mode
		const cbModeEnabled = window.localStorage.getItem("enchant-worldle-cb-mode-enabled") === "true";
		this.setState({
			cbModeEnabled: cbModeEnabled,
		});
		this.setCbModeBodyClasses(cbModeEnabled);
	}
	toggle() {
		this.setState({
			modal: !this.state.modal,
		});
	}

	toggleDarkMode(value) {
		this.setState({
			darkModeEnabled: value,
		});
		// Update localStorage to store this setting
		window.localStorage.setItem("enchant-worldle-dark-mode-enabled", value);

		this.setDarkModeBodyClasses(value);
	}

	toggleCbMode(value) {
		this.setState({
			cbModeEnabled: value,
		});
		// Update localStorage to store this setting
		window.localStorage.setItem("enchant-worldle-cb-mode-enabled", value);

		this.setCbModeBodyClasses(value);
	}

	handleChangePracticeMode(value) {
		this.props.onChangePracticeMode(value);
		console.log("Updating practice mode", value);
		this.setState({
			practiceMode: value,
		});
		// Update localStorage to store this setting
		window.localStorage.setItem("enchant-worldle-practice-mode", value);
	}
	setDarkModeBodyClasses(value) {
		if (value) {
			document.body.classList.add("dark-mode");
		} else {
			document.body.classList.remove("dark-mode");
		}
	}
	setCbModeBodyClasses(value) {
		if (value) {
			document.body.classList.add("cb-mode");
		} else {
			document.body.classList.remove("cb-mode");
		}
	}

	render() {
		return (
			<div className='settings'>
				<div>
					<Button className='settings-button' onClick={this.toggle}>
						<img className='icon' src={SettingsIcon} alt='Settings' />
					</Button>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalHeader toggle={this.toggle}>Settings</ModalHeader>
						<ModalBody>
							<div>
								<div>
									<div className='flex-row'>
										<label>Dark mode</label>
										<Toggle isChecked={this.state.darkModeEnabled} onToggle={this.toggleDarkMode} />
									</div>
									<hr />
									<div className='flex-row'>
										<label>Colour blind mode</label>
										<Toggle isChecked={this.state.cbModeEnabled} onToggle={this.toggleCbMode} />
									</div>
									<hr />

									{/* <div className='flex-row'>
										<label>
											Practice mode
											<br />
											BROKEN, don't use this
										</label>
										<div className='option-group'>
											<Toggle isChecked={this.state.practiceMode} onToggle={this.handleChangePracticeMode} />
											<br />
											Current playing
											<br /> {this.state.practiceMode ? "practice mode" : "daily mode"}
										</div>
									</div> */}
									{/* <div className='flex-row'>
										<label>Format</label>
										<select
											onChange={(e) => {
												this.setState({ format: e.target.value });
											}}>
											<option value='vintage'>Vintage (default)</option>
											<option value='modern'>Modern</option>
											<option value='vintage'>Standard</option>
										</select>
									</div> */}
								</div>
								<hr />
								<div style={{ textAlign: "center" }}>
									<Timer startTimeInSeconds={this.props.timeRemaining()} />
								</div>
								<hr />
								<h3>About</h3>
								<div>
									<p>
										This project was created by{" "}
										<a href='https://draftaholicsanonymous.com' rel='noreferrer' target='_blank'>
											Draftaholics Anonymous
										</a>{" "}
										and{" "}
										<a href='https://gasmtg.com' rel='noreferrer' target='_blank'>
											GASmtg
										</a>
										.
									</p>
									<p>
										<small>This game is unofficial Fan Content permitted under the Fan Content Policy. Not approved/endorsed by Wizards. Portions of the materials used are property of Wizards of the Coast. ©Wizards of the Coast LLC.</small>
									</p>
									<p><small>v.2.0.2</small></p>
								</div>
							</div>
						</ModalBody>
					</Modal>
				</div>
			</div>
		);
	}
}

export default Settings;
