import "./Toggle.scss";
import React, { Component } from "react";

class Toggle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// isChecked: props.isChecked || false,
		};

		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(e) {
		// this.setState({ isChecked: !this.state.isChecked });
		this.props.onToggle(e.target.checked);
	}
	render() {
		const isChecked = this.props.isChecked;
		return (
			<label className='switch'>
				<input type='checkbox' value='1' checked={isChecked} onChange={this.handleChange} />
				<div className='slider'></div>
			</label>
		);
	}
}

export default Toggle;
