import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import InputForm from "./components/InputForm";
import GameOver from "./components/GameOver";
import Guess from "./components/Guess";
import Spindown from "./components/Spindown";
import Ticker from "./components/Ticker";
import Info from "./components/Info";
import Settings from "./components/Settings";
import { Component } from "react";

import cardData from "./cardData";
import setData from "./setData";

let backupGuesses = [];

const totalGuesses = 20;
// January 1, 2022 Game Epoch
const epochMs = new Date(2022, 2, 20).valueOf();
const msInDay = 86400000;

const getNow = () => {
	return Date.now();
};

const getDayNumber = () => {
	// const now = Date.now();
	const now = getNow();
	const diffTime = Math.abs((now - epochMs) / msInDay);
	return Math.floor(diffTime);
};
const dayNumber = getDayNumber();

const getRemainingTimeInDay = () => {
	// const now = Date.now();
	const now = getNow();
	const diffTime = Math.abs((now - epochMs) / msInDay);

	const hours = (Math.ceil(diffTime) - diffTime) * 24;
	const minutes = (hours - Math.floor(hours)) * 60;
	const seconds = (minutes - Math.floor(minutes)) * 60;

	// Seconds remaining in the day
	return (Math.ceil(diffTime) - diffTime) * 24 * 60 * 60;
};

const getCardOfTheDay = () => {
	const index = getDayNumber() * 28192; // A magic number!
	return cardData[index % cardData.length];
};
let correctAnswer = getCardOfTheDay(); //cardData[Math.floor(randomValue * (cardData.length - 1))];
console.log("If you really wanted spoilers, the answer is " + correctAnswer.n);

class App extends Component {
	constructor(props) {
		super(props);
		this.handleAddGuess = this.handleAddGuess.bind(this);
		this.handleChangePracticeMode = this.handleChangePracticeMode.bind(this);
		this.getSetIcons = this.getSetIcons.bind(this);
		this.updateLocalStorageState = this.updateLocalStorageState.bind(this);
		this.resetLocalStorageState = this.resetLocalStorageState.bind(this);
		this.addToStats = this.addToStats.bind(this);

		// Load our state from the localStorage
		let { board, day, completed } = JSON.parse(window.localStorage.getItem("enchant-worldle-state")) ?? [];
		board = board ?? [];
		completed = completed ?? false;

		// If the day isn't set, set it to today's
		day = day ?? dayNumber;

		// If the day's don't match, reset the localStorage
		if (day !== dayNumber) {
			this.resetLocalStorageState();
			this.resetGame();

			// Just refresh the window
			window.location.reload(false);

			board = [];
			completed = false;
		}

		let sessionWasCorrect = false;
		if (completed && JSON.stringify(board[board.length - 1]) === JSON.stringify(correctAnswer)) {
			sessionWasCorrect = true;
		}
		this.state = { practiceMode: false, format: null, guesses: board ?? [], setIcons: this.getSetIcons(), gameFinished: completed ?? false, wasCorrect: sessionWasCorrect ?? false };

		// If we're loading new guesses, fire an event for each one
		let i = 0;
		for (const guess of board) {
			i++;
			const setIconIndex = this.state.setIcons.findIndex((set) => set.code === guess.s);
			this.state.setIcons[setIconIndex].guessed = true;

			if (i >= board.length) {
				// Fire an event that a guess has been added so it scrolls to the latest guess
				const eventGuess = new CustomEvent("ew:guess", { detail: setIconIndex });
				setTimeout(function () {
					window.dispatchEvent(eventGuess);
				}, 100);
			}
		}
	}

	getSetIcons() {
		const list = [];
		for (const set of setData) {
			list.push(set);
		}
		return list;
	}

	handleChangeFormat(newFormat) {
		console.log(newFormat);
	}
	handleChangePracticeMode(isPracticeMode) {
		if (isPracticeMode) {
			correctAnswer = cardData[Math.floor(Math.random() * cardData.length)];
			console.log("Practice mode with", correctAnswer.n);
		} else {
			correctAnswer = getCardOfTheDay();
		}
		let newGuesses = backupGuesses;
		backupGuesses = this.state.guesses;
		this.setState({ practiceMode: isPracticeMode, guesses: newGuesses });
	}
	handleAddGuess(newGuess) {
		this.state.guesses.push(newGuess);
		const setIconIndex = this.state.setIcons.findIndex((set) => set.code === newGuess.s);
		this.state.setIcons[setIconIndex].guessed = true;
		this.setState({ setIcons: this.state.setIcons });

		// Fire an event that a guess has been added
		const eventGuess = new CustomEvent("ew:guess", { detail: setIconIndex });
		window.dispatchEvent(eventGuess);

		// Check if the guess was correct
		if (newGuess === correctAnswer) {
			// End game and prevent further actions
			this.setState({ gameFinished: true, wasCorrect: true });
			this.state.gameFinished = true;
			this.state.wasCorrect = true;
		} else {
			if (this.state.guesses.length >= totalGuesses) {
				this.setState({ gameFinished: true, wasCorrect: false });
				this.state.gameFinished = true;
				this.state.wasCorrect = false;
			}
		}

		if (this.state.gameFinished) {
			this.addToStats();
		} else {
			// Focus on the input field
			document.getElementById("guess-input").focus();
		}

		this.updateLocalStorageState();
	}

	updateLocalStorageState() {
		// Create our data object to push into storage
		const gameState = {
			day: dayNumber,
			board: this.state.guesses,
			completed: this.state.gameFinished,
		};
		console.log(this.state);
		window.localStorage.setItem("enchant-worldle-state", JSON.stringify(gameState));
	}
	resetLocalStorageState() {
		window.localStorage.removeItem("enchant-worldle-state");
	}
	addToStats() {
		// Get our current stats if they exist
		const stats = JSON.parse(window.localStorage.getItem("enchant-worldle-stats")) ?? {
			currentStreak: 0,
			maxStreak: 0,
			guesses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			winPercentage: 0,
			gamesPlayed: 0,
			gamesWon: 0,
			averageGuesses: 0,
		};

		stats.gamesPlayed++;
		if (this.state.wasCorrect) {
			stats.gamesWon++;
			stats.currentStreak++;
			if (stats.maxStreak < stats.currentStreak) {
				stats.maxStreak = stats.currentStreak;
			}
			stats.guesses[this.state.guesses.length - 1]++;
		} else {
			stats.currentStreak = 0;
		}

		// Calculate guessing average
		let total = 0;
		for (let i = 0; i < stats.guesses.length; i++) {
			total += (i + 1) * stats.guesses[i];
		}
		stats.averageGuesses = total / stats.gamesPlayed;

		stats.winPercentage = (stats.gamesWon / stats.gamesPlayed) * 100;
		window.localStorage.setItem("enchant-worldle-stats", JSON.stringify(stats));
	}

	resetGame() {
		correctAnswer = cardData[Math.floor(Math.random() * (cardData.length - 1))];
		this.setState({ guesses: [], setIcons: this.getSetIcons() });
	}

	render() {
		if (dayNumber != getDayNumber()) {
			window.location.reload(false);
		}
		const listOfGuesses = [];
		const guesses = this.state.guesses;
		const wasCorrect = this.state.wasCorrect;

		guesses.forEach((guess, i) => {
			listOfGuesses.unshift(<Guess key={i} {...guess} guessNumber={i + 1} correctAnswer={correctAnswer}></Guess>);
		});

		return (
			<div className='App'>
				<div id='header'>
					<h1>Enchant Worldle</h1>
					<p className='tagline'>{this.state.practiceMode ? "Practice mode" : "#" + dayNumber}</p>
					<Info />
					<Settings timeRemaining={getRemainingTimeInDay} format={this.state.format} practiceMode={this.state.practiceMode} onChangePracticeMode={this.handleChangePracticeMode} onChangeFormat={this.handleChangeFormat} />
				</div>
				<Ticker correctAnswer={correctAnswer} sets={[...this.state.setIcons]} />
				<div className='flex-row input-wrapper'>
					{!this.state.gameFinished && <InputForm currentGuess={guesses.length} totalGuesses={totalGuesses} onAddGuess={this.handleAddGuess} />}
					{this.state.gameFinished && (
						<div>
							<h2>The card was {correctAnswer.n}</h2>
						</div>
					)}
					{this.state.gameFinished && <GameOver dayNumber={dayNumber} guesses={guesses} correctAnswer={correctAnswer} wasCorrect={wasCorrect} totalGuesses={totalGuesses} />}

					{!this.state.gameFinished && <Spindown currentGuess={totalGuesses - guesses.length}></Spindown>}
				</div>
				<div className='guess-table-wrapper'>
					<table className='guess-table'>
						<thead>
							<tr>
								<th>#</th>
								<th>Card name</th>
								<th>Mana value</th>
								<th>Colours</th>
								<th>Rarity</th>
								<th>Type / Supertype</th>
								<th>Subtype</th>
								<th>Set</th>
							</tr>
						</thead>
						<tbody>{listOfGuesses}</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default App;
