import "./Guess.scss";

function Guess(props) {
	const { guessNumber, n, mv, c, r, s, t, st, d, correctAnswer } = props;
	const type = t;
	const rarity = r;
	const colours = c;
	const subtype = st;
	const set = s;
	const name = n;

	// Track if all fields are right, and show name up/down arrows if it's the only wrong thing
	let allAnswersCorrect = true;

	const partialGuessBackground = (correct, incorrect, total, rotation) => {
		let background;
		rotation = rotation ?? "90deg";
		if (correct === 0) {
			background = "var(--wrong-color)";
		} else if (correct + incorrect === total && incorrect === 0) {
			background = "var(--correct-color)";
		} else {
			let breakpoint = (correct / (correct + incorrect)) * 100;
			background = "linear-gradient(" + rotation + ", var(--almost-correct-color) 0%, var(--almost-correct-color) " + breakpoint + "%, var(--wrong-color) " + breakpoint + "%, var(--wrong-color) 100%)";

			// background = 'conic-gradient(from 90deg at 50% 50%, '+var(--wrong-color)+' 0%, '+var(--wrong-color)+' 24.9%, '+var(--almost-correct-color)+' 25%, '+var(--almost-correct-color)+' 57%, '+var(--wrong-color)+' 57%, '+var(--wrong-color)+' 100%, '+var(--wrong-color)+' 100%)'
			// background = "conic-gradient(from 0deg at 50% 50%, var(--wrong-color) 0%, var(--wrong-color) " + breakpoint + "%, var(--almost-correct-color) " + breakpoint + "%, var(--almost-correct-color) 100%)";
		}

		return background;
	};

	let titleSet = 'Incorrect';
	const getClassRelease = () => {
		let classRelease = "guess-set";

		const correctYear = correctAnswer.d.split("-")[0];
		const year = d.split("-")[0];
		diff = correctYear - year;
		if (correctAnswer.s === set) {
			classRelease += " correct";
			titleSet = 'Correct';
		} else if (correctYear === year) {
			classRelease += " almost-correct";
			allAnswersCorrect = false;
			titleSet = 'Almost correct';
		} else {
			classRelease += " wrong";
			allAnswersCorrect = false;
		}

		let dateDiff = parseInt(correctAnswer.d.replace("-", "")) - parseInt(d.replace("-", ""));

		if (dateDiff > 0) {
			classRelease += " up";
		} else if (dateDiff < 0) {
			classRelease += " down";
		}

		return classRelease;
	};

	// Correctness indicators
	// Mana value
	let classMv = "guess-mv";
	let diff = correctAnswer.mv - mv;
	let titleMv = 'Incorrect';
	if (diff === 0) {
		classMv += " correct";
		titleMv = 'Correct';
	} else if (diff <= 2 && diff >= -2) {
		classMv += " almost-correct";
		titleMv = 'Almost correct';
		allAnswersCorrect = false;
	} else {
		classMv += " wrong";
		allAnswersCorrect = false;
	}

	if (diff > 0) {
		classMv += " up";
	} else if (diff < 0) {
		classMv += " down";
	}

	// Release date
	const classRelease = getClassRelease();

	// Colours
	let classColour = "guess-colour";
	let coloursCorrect = 0;
	let coloursIncorrect = 0;
	let colourBarStyle = [];
	let titleColours = 'Incorrect';

	if ((correctAnswer.c.length === 0 && colours.length === 0) || JSON.stringify(correctAnswer.c) === JSON.stringify(colours)) {
		classColour += " correct";
		titleColours = 'Correct';
	} else {
		allAnswersCorrect = false;
		if (colours) {
			for (const colour of colours) {
				if (correctAnswer.c.includes(colour)) {
					coloursCorrect++;
				} else {
					coloursIncorrect++;
				}
			}
		}
		colourBarStyle["background"] = partialGuessBackground(coloursCorrect, coloursIncorrect, correctAnswer.c.length, "0deg");
		if (coloursCorrect > 0) {
			titleColours = 'Partly correct';
		}
	}

	// Rarity
	let classRarity = "guess-rarity";
	const rarityLookup = {
		common: 1,
		uncommon: 2,
		rare: 3,
		mythic: 4,
	};
	let rarityArrow = null;
	let titleRarity = 'Incorrect';

	if (correctAnswer.r === rarity) {
		classRarity += " correct";
		titleRarity = 'Correct';
	} else {

		allAnswersCorrect = false;
		classRarity += " wrong";
		if (rarityLookup[correctAnswer.r] - rarityLookup[rarity] > 0) {
			rarityArrow = <span className='up'></span>;
		} else {
			rarityArrow = <span className='down'></span>;
		}
	}

	// Type
	let classType = "guess-type";
	let typeBarStyle = [];
	let typesCorrect = 0;
	let typesIncorrect = 0;
	let titleType = 'Incorrect';
	if (JSON.stringify(correctAnswer.t) === JSON.stringify(type)) {
		classType += " correct";
		titleType = 'Correct';
	} else {
		allAnswersCorrect = false;
		type.forEach((typePart) => {
			if (correctAnswer.t.includes(typePart)) {
				typesCorrect++;
			} else {
				typesIncorrect++;
			}
		});
		typeBarStyle["background"] = partialGuessBackground(typesCorrect, typesIncorrect, correctAnswer.t.length);
		if (typesCorrect > 0) {
			titleType = 'Partly correct';
		}
	}
	let formattedType = type.reduce((previousValue, currentValue, currentIndex) => {
		return (previousValue += " " + currentValue);
	});

	// Subtype
	let classSubtype = "guess-subtype";
	let subtypeBarStyle = [];
	let subtypesCorrect = 0;
	let subtypesIncorrect = 0;
	let titleSubtype = 'Incorrect';
	if (JSON.stringify(correctAnswer.st) === JSON.stringify(subtype)) {
		classSubtype += " correct";
		titleSubtype = 'Correct';
	} else {
		allAnswersCorrect = false;
		subtype.forEach((subtypePart) => {
			if (correctAnswer.st.includes(subtypePart)) {
				subtypesCorrect++;
			} else {
				subtypesIncorrect++;
			}
		});
		subtypeBarStyle["background"] = partialGuessBackground(subtypesCorrect, subtypesIncorrect, correctAnswer.st.length);
		if (subtypesCorrect > 0) {
			titleSubtype = 'Almost correct';
		}
	}
	let formattedSubtype = [];
	if (subtype.length) {
		formattedSubtype = subtype.reduce((previousValue, currentValue, currentIndex) => {
			return (previousValue += " " + currentValue);
		});
	}

	const colourLookup = { W: 0, U: 1, B: 2, R: 3, G: 4 };
	const colourTitleLookup = { W: "White", U: "Blue", B: "Black", R: "Red", G: "Green" };
	let orderedColours = [];
	for (const c of colours.sort((a, b) => colourLookup[a] - colourLookup[b])) {
		orderedColours.push(<span key={c} className={"colour-symbol colour-symbol--" + c} title={colourTitleLookup[c]}></span>);
	}
	if (!orderedColours.length) {
		orderedColours.push(<span key='colourless' className='colour-symbol colour-symbol--colourless' title="Colourless"></span>);
	}

	let classNameField = "guess-name";
	let titleName = 'Incorrect';

	if (correctAnswer.n == name) {
		classNameField = "correct";
		titleName = 'Correct';
	} else if (allAnswersCorrect && correctAnswer.n != name) {
		classNameField += " almost-correct " + (correctAnswer.n < name ? "up" : "down");
		titleName = 'Almost correct';
	}
	return (
		<tr className='guess'>
			<td>#{guessNumber}</td>
			<td className={classNameField} title={titleName}>{name}</td>
			<td className={classMv} title={titleMv}>
				<span>{mv}</span>
			</td>
			<td className={classColour} title={titleColours}>
				<div className='bar-wrapper'>
					<div className='colour-wrapper'>{orderedColours}</div>
					<div className={"indicator-bar"} style={colourBarStyle}></div>
				</div>
			</td>
			<td className={classRarity} title={titleRarity}>
				<span className={"rarity-icon rarity" + rarity[0].toUpperCase()} title={rarity}></span>
				{rarityArrow}
				{/* <strong className={"text-tiny"}>{rarity}</strong> */}
			</td>
			<td className={classType} title={titleType}>
				<div className='bar-wrapper'>
					{formattedType}
					<div className={"indicator-bar"} style={typeBarStyle}></div>
				</div>
			</td>
			<td className={classSubtype} title={titleSubtype}>
				<div className='bar-wrapper'>
					{formattedSubtype}
					<div className={"indicator-bar"} style={subtypeBarStyle}></div>
				</div>
			</td>
			<td className={classRelease} title={titleSet}>
				<img src={"https://c2.scryfall.com/file/scryfall-symbols/sets/" + set + ".svg"} alt={set} className='set-icon' /> {"'" + d.split("-")[0].slice(2)}
			</td>
		</tr>
	);
}

export default Guess;
