import React, { Component } from "react";
class Timer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seconds: parseInt(props.startTimeInSeconds, 10) || 0,
		};
	}

	tick() {
		this.setState((state) => ({
			seconds: state.seconds - 1,
		}));
	}

	componentDidMount() {
		this.interval = setInterval(() => this.tick(), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	formatTime(secs) {
		let hours = Math.floor(secs / 3600);
		let minutes = Math.floor(secs / 60) % 60;
		let seconds = secs % 60;
		return [hours, minutes, seconds]
			.map((v) => ("" + v).padStart(2, "0"))
			.filter((v, i) => v !== "00" || i > 0)
			.join(":");
	}

	render() {
		if (this.state.seconds < 0) {
			window.location.reload(false);
			return <div>A new game has started. Reload your window.</div>;
		}
		return (
			<div>
				<span>Time until next daily game</span>
				<h2>{this.formatTime(this.state.seconds)}</h2>
			</div>
		);
	}
}

export default Timer;
