import "./InputForm.scss";

import React, { Component } from "react";
import cardData from "../cardData";

const initialState = { value: "", msg: "", autocomplete: [], activeSuggestionIndex: -1 };

class InputForm extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleAddGuess = this.handleAddGuess.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onSelectCard = this.onSelectCard.bind(this);
		this.addLegalGuess = this.addLegalGuess.bind(this);
		this.reset = this.reset.bind(this);
		this.setActiveSuggestionIndex = this.setActiveSuggestionIndex.bind(this);
		this.focusOnAutocompleteOption = this.focusOnAutocompleteOption.bind(this);
	}
	reset() {
		this.setState(initialState);
	}

	handleChange(e) {
		const currentValue = e.target.value;
		const currentValueLower = currentValue.toLowerCase();

		// Update our autocomplete
		let filteredSet = [];
		if (currentValue !== "" && currentValue.length > 2) {
			filteredSet = cardData.filter((card) => card.n.toLowerCase().indexOf(currentValueLower) >= 0);
		}

		// Set the value and our updated autocomplete
		this.setState({ value: currentValue, msg: "", autocomplete: filteredSet, activeSuggestionIndex: 0 });

		// Update the list
		// Set the active index selection (for keyboard nav)
		// Show the autocomplete box
	}
	handleAddGuess(guess) {
		this.props.onAddGuess(guess);
	}
	handleSubmit(event) {
		event.preventDefault();
		if (this.state.value === "") {
			return;
		}

		const foundIndex = cardData.findIndex((card, i) => card.n.toLowerCase() === this.state.value.toLowerCase());
		if (foundIndex >= 0) {
			this.addLegalGuess(cardData[foundIndex]);
		} else {
			this.setState({ msg: this.state.value + " is not a Magic card", value: "" });
		}
	}
	addLegalGuess(card) {
		// One last check to make sure it's legit
		this.handleAddGuess(card);
		this.reset();
	}
	onSelectCard(e, card) {
		this.addLegalGuess(card);
		this.setActiveSuggestionIndex(0);
	}
	setActiveSuggestionIndex(index) {
		this.setState({ activeSuggestionIndex: index }, this.focusOnAutocompleteOption);
	}
	focusOnAutocompleteOption() {
		const focused = document.querySelector(".card-active");
		if (focused) {
			focused.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}
	onKeyDown(e) {
		if (e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
			if (e.keyCode === 13) {
				// Enter key - submit the selected value
				// this.addLegalGuess(this.state.autocomplete[this.state.activeSuggestionIndex]);
				const card = this.state.autocomplete[this.state.activeSuggestionIndex];
				const newValue = card ? card.n : e.target.value;
				this.setState({ value: newValue });
				this.state.value = newValue;
				this.handleSubmit(e);
				e.preventDefault();
				return;
			} else if (e.keyCode === 40) {
				// Down arrow
				let newIndex = this.state.activeSuggestionIndex + 1;
				if (newIndex >= this.state.autocomplete.length) {
					newIndex = this.state.autocomplete.length - 1;
				}
				this.setActiveSuggestionIndex(newIndex);
			} else if (e.keyCode === 38) {
				// Up arrow
				let newIndex = this.state.activeSuggestionIndex - 1;
				if (newIndex < 0) {
					newIndex = 0;
				}
				this.setActiveSuggestionIndex(newIndex);
			}

			e.preventDefault();
			return;
		}
	}

	render() {
		const { currentGuess, totalGuesses } = this.props;
		return (
			<form onSubmit={this.handleSubmit}>
				<div className='form-input-wrapper'>
					<input
						id='guess-input'
						type='text'
						className='input-control'
						placeholder={currentGuess === 0 ? "Type a card name..." : "Guess " + (currentGuess + 1) + " of " + totalGuesses}
						value={this.state.value}
						onKeyDown={(e) => {
							this.onKeyDown(e);
						}}
						onChange={this.handleChange}
					/>{" "}
					{this.state.autocomplete.length ? (
						<ul className='autocomplete'>
							{this.state.autocomplete.map((card, index) => {
								let className;
								// Flag the active card with a class
								if (index === this.state.activeSuggestionIndex) {
									className = "card-active";
								}
								return (
									<li
										className={className}
										key={"c" + index}
										onClick={(e) => {
											this.onSelectCard(e, card);
										}}>
										{card.n}
									</li>
								);
							})}
						</ul>
					) : (
						""
					)}
				</div>{" "}
				{/* <div className='form-input-wrapper'>
					<input type='submit' className='input-control' value='Submit' />
				</div> */}
				<div>{this.state.msg}</div>
			</form>
		);
	}
}

export default InputForm;
