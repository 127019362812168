import "./GameOver.scss";
import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ShareButton from "./ShareButton";

class GameOver extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			stats: JSON.parse(window.localStorage.getItem("enchant-worldle-stats")) ?? [],
		};
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.setState({
			modal: !this.state.modal,
		});
	}

	render() {
		const { dayNumber, guesses, correctAnswer, wasCorrect, totalGuesses } = this.props;
		const stats = this.state.stats;

		// Format the url
		let imgUrl = correctAnswer.img;
		if (!imgUrl.startsWith("http")) {
			imgUrl = "https://c1.scryfall.com/file/scryfall-cards/normal" + imgUrl;
		}

		const title = wasCorrect ? "You guessed it!" : "Maybe tomorrow...";
		return (
			<div className='gameover'>
				<div>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalHeader toggle={this.toggle}>{title}</ModalHeader>
						<ModalBody className='text-center'>
							<p className='mb-0'>Today's card was</p>
							<h2>{correctAnswer.n}</h2>
							<div className='text-center mt-2'>
								<ShareButton dayNumber={dayNumber} guesses={guesses} correctAnswer={correctAnswer} wasCorrect={wasCorrect} totalGuesses={totalGuesses} />
							</div>
							<div className='mt-4'>
								<img className='magic-card' src={imgUrl} alt={correctAnswer.n} />
							</div>
							<p>#{dayNumber}</p>
							{stats && (
								<table className='stats-table'>
									<tbody>
										<tr>
											<td>Current streak</td>
											<td>{stats.currentStreak}</td>
										</tr>
										<tr>
											<td>Max streak</td>
											<td>{stats.maxStreak}</td>
										</tr>
										<tr>
											<td>Win percentage</td>
											<td>{stats.winPercentage.toFixed(2)}%</td>
										</tr>
										<tr>
											<td>Games played</td>
											<td>{stats.gamesPlayed}</td>
										</tr>
										<tr>
											<td>Games won</td>
											<td>{stats.gamesWon}</td>
										</tr>
										<tr>
											<td>Average guesses for wins</td>
											<td>{stats.averageGuesses.toFixed(2)}</td>
										</tr>
									</tbody>
								</table>
							)}
						</ModalBody>
					</Modal>
				</div>
			</div>
		);
	}
}

export default GameOver;
