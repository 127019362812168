import "./Info.scss";
import InfoIcon from "../icon-info.svg";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class Info extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: !window.localStorage.getItem("enchant-worldle-has-closed-modal"),
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal,
		});

		window.localStorage.setItem("enchant-worldle-has-closed-modal", true);
	}

	render() {
		return (
			<div className='info'>
				<div>
					<Button className='info-button' onClick={this.toggle}>
						<img className='icon' src={InfoIcon} alt='i' />
					</Button>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalHeader toggle={this.toggle}>What's going on here?</ModalHeader>
						<ModalBody>
							<div>
								<h3>How to play</h3>
								<div>
									<p>
										Guess the Magic card in 20 guesses. <em>Enchant Worldle</em> uses the first printing of each card.
									</p>
									<h4>The clues</h4>
									<p>
										A <span className='correct'>completely green background</span> in any position means you've got this part correct.
									</p>
									<h5>Indicator bars</h5>
									<ul>
										<li>Indicator bars (used in Colour identity, Type and Subtype columns) indicates the percentage correctness.</li>
										<li>
											A bar that is one third <span className='almost-correct'>yellow</span> indicates 1/3 of your colours/types are correct.
										</li>
										<li>
											A full bar <span className='almost-correct'>yellow</span> indicates all of those colours/types are correct, but there are more on the correct card.
										</li>
									</ul>

									<h5>Mana value</h5>
									<ul>
										<li>This is the mana value (previously converted mana cost) of the card.</li>
										<li>
											<span className='almost-correct'>Yellow in this position</span> means you're within +/- 2 of the correct value.
										</li>
									</ul>
									<h5>Colour identity</h5>
									<ul>
										<li>This is the colour identity (most often seen when building Commander decks).</li>
									</ul>
									<h5>Set / year</h5>
									<ul>
										<li>
											<span className='almost-correct'>Yellow in this position</span> means you've got the <strong>year</strong> right, but not the set.
										</li>
									</ul>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button color='primary' onClick={this.toggle}>
								Let's play!
							</Button>{" "}
						</ModalFooter>
					</Modal>
				</div>
			</div>
		);
	}
}

export default Info;
