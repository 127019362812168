import "./Ticker.scss";
import React, { Component } from "react";
import Swiper from "swiper/bundle";
import "swiper/css";

class Ticker extends Component {
	constructor(props) {
		super(props);
		this.state = { sets: [...props.sets], correctAnswer: props.correctAnswer };
		this.setState(this.state);
	}

	componentDidMount() {
		const component = this;
		// init Swiper:
		const swiper = new Swiper(".ticker.swiper", {
			// loop: true,
			speed: 100,
			centeredSlides: true,
			snapOnRelease: true,
			grabCursor: true,
			slidesPerView: "auto",
		});
		window.addEventListener("ew:guess", (e) => {
			setTimeout(function () {
				swiper.loopDestroy();
				swiper.loopCreate();
				swiper.slideTo(e.detail, 800);
			}, 50);
		});
	}

	render() {
		const sets = this.state.sets;
		const correctAnswer = this.state.correctAnswer;
		const listOfSets = [];
		sets.forEach((set, i) => {
			let year = set.released_at.split("-")[0];
			let guessed = "";
			if (set.guessed) {
				let correctYear = correctAnswer.d.split("-")[0];
				if (correctAnswer.s === set.code) {
					guessed = "guessed-correct";
				} else if (correctYear === year) {
					guessed = "guessed-almost-correct";
				} else {
					guessed = "guessed-wrong";
				}
			}
			const setIconTitle = set.name + " (" + set.code.toUpperCase() + " - " + year + ")";
			listOfSets.push(
				<div className='swiper-slide' key={set.code}>
					<img key={set.code} className={"set-icon " + guessed} alt={setIconTitle} title={setIconTitle} src={process.env.PUBLIC_URL + "/img/" + set.code + ".svg"} />
				</div>
			);
		});
		return (
			<div className={"ticker swiper"}>
				<div className='ticker__list swiper-wrapper' id='marquee'>
					{listOfSets}
				</div>
			</div>
		);
	}
}

export default Ticker;
