import "./ShareButton.scss";

function fallbackCopyToClipboard(text) {
	var textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		var successful = document.execCommand("copy");
		var msg = successful ? "successful" : "unsuccessful";
		console.log("Fallback: Copying text command was " + msg);
		if (successful) {
			showTooltip();
		}
	} catch (err) {
		console.error("Fallback: Oops, unable to copy", err);
	}

	document.body.removeChild(textArea);
}
function copyToClipboard(text) {
	if (!navigator.clipboard) {
		fallbackCopyToClipboard(text);
		return;
	}
	navigator.clipboard.writeText(text).then(
		function () {
			// console.log("Async: Copying to clipboard was successful!");
			showTooltip();
		},
		function (err) {
			console.error("Async: Could not copy text: ", err);
		}
	);
}
const showTooltip = () => {
	const tooltip = document.getElementById("tooltip");
	tooltip.style.opacity = 1;
	setTimeout(function () {
		const tooltip = document.getElementById("tooltip");
		tooltip.style.opacity = 0;
	}, 2000);
};

function ShareButton(props) {
	const { guesses, totalGuesses, correctAnswer, wasCorrect, dayNumber } = props;

	let copyText = "Enchant Worldle #" + dayNumber + " " + (wasCorrect ? guesses.length : "X") + "/" + totalGuesses + "\nhttps://enchantworldle.com\n";
	for (const guess of guesses) {
		copyText += "\n";

		// Mana value
		const mvDiff = guess.mv - correctAnswer.mv;
		if (mvDiff === 0) {
			copyText += "🟩";
		} else if (mvDiff >= -2 && mvDiff <= 2) {
			copyText += "🟨";
		} else {
			copyText += "⬛";
		}

		const lookForMatches = (a, b) => {
			// Order the arrays first
			a.sort();
			b.sort();
			if ((b.length === 0 && a.length === 0) || JSON.stringify(b) === JSON.stringify(a)) {
				return "🟩";
			} else {
				if (a) {
					for (const val of a) {
						if (b.includes(val)) {
							return "🟨";
						}
					}
				}
			}
			return "⬛";
		};

		// Colour identity
		copyText += lookForMatches(guess.c, correctAnswer.c);

		// Rarity
		if (guess.r === correctAnswer.r) {
			copyText += "🟩";
		} else {
			copyText += "⬛";
		}

		// Type
		copyText += lookForMatches(guess.t, correctAnswer.t);

		// Subtype
		copyText += lookForMatches(guess.st, correctAnswer.st);

		// Set / year
		const correctYear = correctAnswer.d.split("-")[0];
		const year = guess.d.split("-")[0];
		if (correctAnswer.s === guess.s) {
			copyText += "🟩";
		} else if (correctYear === year) {
			copyText += "🟨";
		} else {
			copyText += "⬛";
		}
	}
	return (
		<div className='share-button-wrapper'>
			<div className='tooltip' id='tooltip'>
				Copied to clipboard
			</div>
			<button
				style={{ position: "relative" }}
				className='btn btn-primary share-button btn-lg'
				onClick={function () {
					copyToClipboard(copyText);
				}}>
				Share
			</button>
		</div>
	);
}

export default ShareButton;
