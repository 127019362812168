import "./Spindown.scss";

function Spindown(props) {
	const { currentGuess } = props;

	let faces = [];
	for (let i = 1; i <= 20; i++) {
		faces.push(<figure key={i} className={"face face-" + i + (i === currentGuess ? " face--current" : "")}></figure>);
	}

	return (
		<div>
			<div className='die-wrapper'>
				<div className='die' data-face={currentGuess}>
					{faces}
				</div>
			</div>
		</div>
	);
}

export default Spindown;
